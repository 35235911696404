<template>
  <div v-if="model">
    <div class="row justify-content-center">
      <div class="form-group col-4">
        <label for>Password</label>
        <fg-input
          readonly
          type="password"
          name="password"
          placeholder="Password"
          v-model="model.password"
          v-validate="modelValidations.password"
          :error="getError('password')"
        ></fg-input>
      </div>
      <div class="form-group col-4">
        <label for>Blood Group</label>
        <fg-input
          readonly
          name="blood_group"
          v-model="model.blood_group"
          v-validate="modelValidations.blood_group"
          :error="getError('blood_group')"
        >
          <el-select
            name="blood_group"
            class="select-defaul"
            placeholder="Pick a Blood Group"
            v-model="model.blood_group"
          >
            <el-option
              v-for="option in local.bloods"
              class="select-success"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Height</label>
        <fg-input
          readonly
          type="number"
          name="height"
          placeholder="Height"
          v-model="model.height"
          v-validate="modelValidations.height"
          :error="getError('height')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Weight</label>
        <fg-input
          readonly
          type="number"
          name="weight"
          placeholder="Weight"
          v-model="model.weight"
          v-validate="modelValidations.weight"
          :error="getError('weight')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Address</label>
        <fg-input
          readonly
          type="text"
          name="address_line"
          placeholder="Address"
          v-model="model.address_line"
          v-validate="modelValidations.address_line"
          :error="getError('address_line')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>State</label>
        <fg-input
          readonly
          type="text"
          name="state"
          placeholder="State"
          v-model="model.state"
          v-validate="modelValidations.state"
          :error="getError('state')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Pincode</label>
        <fg-input
          readonly
          type="number"
          name="pin"
          placeholder="Pincode"
          v-model="model.pin"
          v-validate="modelValidations.pin"
          :error="getError('pin')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Country</label>
        <fg-input
          readonly
          type="text"
          name="country"
          placeholder="Country"
          v-model="model.country"
          v-validate="modelValidations.country"
          :error="getError('country')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Location</label>
        <fg-input
          readonly
          type="text"
          name="location_id"
          v-model="model.location_id"
          v-validate="modelValidations.location_id"
          :error="getError('location_id')"
        >
          <el-select
            name="location_id"
            class="select-defaul"
            placeholder="Clinic Location"
            v-model="model.location_id"
          >
            <el-option
              v-for="option in locations"
              class="select-success"
              :value="option.value"
              :label="`${option.location_id} - ${option.name}`"
              :key="option.label"
            ></el-option>
          </el-select>
        </fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Known Diseases</label>
        <el-select
          v-model="model.known_diseases"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="Choose tags for your article"
        ></el-select>
      </div>

      <div class="form-group col-4">
        <label for>Lead</label>
        <div>
          <p-switch v-model="model.isLead" on-text="Yes" off-text="No"></p-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Upload, DatePicker, Select, Option } from "element-ui";
import { Switch } from "src/components/UIComponents";
import { mapGetters } from "vuex";
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  computed: {
    ...mapGetters({
      locations: "getLocations"
    })
  },
  mounted() {
    this.$store.dispatch("fetchLocationsAll");
  },
  props: ["model"],
  data() {
    return {
      local: {
        bloods: [
          { value: "A+", label: "A+" },
          { value: "B+", label: "B+" },
          { value: "A-", label: "A-" },
          { value: "B-", label: "B-" },
          { value: "O+", label: "O+" },
          { value: "O-", label: "O-" },
          { value: "AB+", label: "AB+" },
          { value: "AB-", label: "AB-" }
        ]
      },
      modelValidations: {
        password: {
          // required: true,
          min: 6
        },
        location_id: {
          // required: true
        },
        blood_group: {
          required: false
        },
        height: {
          required: false,
          max: 3
        },
        weight: {
          required: false,
          max: 3
        },
        address_line: {
          required: false
        },
        state: {
          required: false
        },
        pin: {
          required: false,
          min: 6,
          max: 6
        },
        country: {
          required: false
        }
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
